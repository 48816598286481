<template>
  <div class="job-layout">
    <div class="login-box">
      <div class="login-bg"></div>
      <div class="login-content">
        <!-- 标题 -->
        <div class="login-title-box">
          <div class="login-title-box-top">海峡人才网招聘会</div>
          <div class="login-title-box-bom">账号密码登录</div>
        </div>
        <!-- 表单 -->

        <div class="login-form">
          <van-form @submit="onSubmit">
            <van-field class="username" v-model="loginForm.account" placeholder="请输入账号" :rules="[{ required: true, message: '' }]" />
            <div class="flex between" style="position:relative">
              <van-field class="password" v-model="password" :type="showPassword ? 'text' : 'password'" placeholder="请输入密码" :rules="[{ required: true, message: '' }]" />
              <div @click="toggleShowPassword" class="ese-password">
                <van-icon v-if="showPassword" name="http://hxzp-web-hxrc-user.oss-cn-hangzhou.aliyuncs.com/wechatApp_icon/hlj/scan/preview-open.svg" size="20" />
                <van-icon v-else name="http://hxzp-web-hxrc-user.oss-cn-hangzhou.aliyuncs.com/wechatApp_icon/hlj/scan/preview-close-one.svg" size="20" />
              </div>
            </div>


            <div class="flex between" style="position:relative"  v-if="openSecurityCode == 1" >
              <van-field class="password" v-model="loginForm.securityCode" :type="showSecurityCode ? 'text' : 'password'" placeholder="请输入安全码" :rules="[{ required: true, message: '' }]" />
              <div @click="toggleShowSecurityCode" class="ese-password">
                <van-icon v-if="showSecurityCode" name="http://hxzp-web-hxrc-user.oss-cn-hangzhou.aliyuncs.com/wechatApp_icon/hlj/scan/preview-open.svg" size="20" />
                <van-icon v-else name="http://hxzp-web-hxrc-user.oss-cn-hangzhou.aliyuncs.com/wechatApp_icon/hlj/scan/preview-close-one.svg" size="20" />
              </div>
            </div>

            <div class="flex between">
              <van-field class="imgCode flex_1" v-model="loginForm.captchaVal" type="username" placeholder="请输入验证码" :rules="[{ required: true, message: '' }]" />
              <img @click="refreshImgCode" class="base64Img" :src=" `data:image/png;base64,${code}`" alt="">
            </div>
            
            <div class="login-btn">
              <van-button square block type="info" native-type="submit">登录</van-button>
            </div>
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CryptoJs from 'crypto-js'
// import { mapActions } from "vuex";
export default {
  name: 'Login',
  data () {
    return {
      openSecurityCode: '0',
      loginForm: {
        account: '',
        password: '',
        captchaVal: '',
        captchaId: '',
        publicKey: this.$jsrsasings.getPublicKey()
      },
      showPassword: false,
      showSecurityCode: false,
      code: '',
      password: ''
    }
  },
  mounted () {
    this.$api.home.getSysConfig({ codes: ['login_security_code'] }).then(res => {
      this.openSecurityCode = res.data.login_security_code
      console.log( this.openSecurityCode)
    })
    this.getVerificationCode()
  },
  methods: {
    toggleShowPassword () {
      this.showPassword = !this.showPassword;
    },
    toggleShowSecurityCode () {
      this.showSecurityCode = !this.showSecurityCode;
    },
    refreshImgCode () {
      this.getVerificationCode()
    },
    getVerificationCode () {
      this.$api.home
        .getVerificationCode()
        .then( res => {
          this.code = res.data.base64Img
          this.loginForm.captchaId = res.data.captchaId
        } )
        .catch( () => {

        } )
    },
    // ...mapActions("user", ["login"]),
    onSubmit () {
      // console.log( this.loginForm )
      this.loginForm.password = CryptoJs.MD5( this.password + this.password ).toString()
      this.$store.dispatch( 'user/Login', this.loginForm ).then( ( r ) => {
        this.$router.push( '/home' )
      } ).catch( () => {
      } )


    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .van-button--square {
  border-radius: 5px !important;
}
.login-box {
  height: 100%;
  background: linear-gradient(180deg, #4097ff 0%, #ffffff 100%);
  .login-bg {
    background-image: url('http://hxzp-web-hxrc-user.oss-cn-hangzhou.aliyuncs.com/wechatApp_icon/hlj/scan/login.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    right: -42px;
    top: 5px;
    width: 300px;
    height: 300px;
  }
  .login-content {
    margin-top: 85px;
    padding: 0 30px;
    .login-title-box {
      .login-title-box-top {
        font-size: 31px;
        font-weight: 600;
        color: #ffffff;
      }
      .login-title-box-bom {
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  .login-form {
    margin-top: 58px;
    .username {
      margin-bottom: 15px;
      border-radius: 5px;
    }
    .password {
      border-radius: 5px;
      margin-bottom: 15px;
    }
    .imgCode {
      border-radius: 5px 0 0 5px;
    }
    .base64Img {
      width: 100px;
      border-radius: 0 5px 5px 0;
      object-fit: cover;
    }
    .login-btn {
      margin-top: 45px;
    }
  }
}
.ese-password {
  position: absolute;
  height: 44px;
  width: 44px;
  right: 0;
  line-height: 44px;
  text-align: center;
}
</style>
